<template>
  <div class="all-height all-width">
    <div class="">
      <v-card loader-height="2" class="shadow-off" :loading="loading" :disabled="loading">
        <v-card-text class="">
          <lb-filter :filterheaders="filterOptions" @loadFilterData="getFilterData" />
          <div>
            <div class="d-flex mb-0 position-absolute">
              <div class="d-flex align-center">
                <span class="display-1">{{meta.totalCount}}</span>
                <span class="ml-2 line-1">Reporting Units<br />Count</span>
              </div>
              <!-- <v-spacer></v-spacer>
              <v-btn class="shadow-off" icon @click="refreshData();"><v-icon>mdi-refresh</v-icon></v-btn> -->
            </div>

            <lb-datatable_pagination :headers="headers" :items="formatedData"
              :enableslot="['action', 'usersflag', 'materialityflag_actual', 'materialityflag_forecast','temporary','role']"
              :loading="false" v-if="formatedData.length > 0" :multiselectkey="isViewOnly === false ? '_id' : ''" @loadNextPage="loadNextPage"
              :page="page" :metaData="meta" :filterheaders="filterOptions" @loadFilterData="getRefreshData">

              <template v-slot:additionalinfo="{ item, selected, selectall }">
                <v-tooltip bottom content-class="tooltip-bottom"
                  v-if="$nova.hasRight('commentary_permission_user', 'read') && (selected.length>1 || selectall.length>1)">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon small color="primaryicon" v-bind="attrs" v-on="on"
                      @click="bulkenableworkflowdialogue = true, enabledisableworkflowdata = {}, enabledisableworkflowdata['reportingunits']=selected">
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                  <span>Enable/Disable workflow</span>
                </v-tooltip>
                <v-tooltip bottom content-class="tooltip-bottom"
                  v-if="$nova.hasRight('commentary_permission_user', 'read') && (selected.length > 1 || selectall.length > 1)">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon small color="secondaryicon" v-bind="attrs" v-on="on"
                      @click="bulkenablebulkapprovedialogue = true, enabledisablebulkapprovedata = {}, enabledisablebulkapprovedata['_id'] = selected">
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                  <span>Enable/Disable bulk approve</span>
                </v-tooltip>
                <v-tooltip bottom content-class="tooltip-bottom"
                    v-if="$nova.hasRight('commentary','approve') || $nova.hasRight('commentary', 'admin') ||$nova.hasRight('commentary_permission_user', 'read') && isViewOnly=== false">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" icon class="shadow-off"
                      @click="getDownloadAllAccesslist();"><v-icon>mdi-table-arrow-down</v-icon></v-btn>
                  </template>
                  <span>Download All Access Detail</span>
                </v-tooltip>
                <v-tooltip bottom content-class="tooltip-bottom"
                    v-if="$nova.hasRight('commentary','approve') || $nova.hasRight('commentary', 'admin') ||$nova.hasRight('commentary_permission_user', 'read') && isViewOnly=== false">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" icon class="shadow-off"
                      @click="getDownloadRUMaterialitylist();"><v-icon>mdi-table-arrow-down</v-icon></v-btn>
                  </template>
                  <span>Download All RU Materiality</span>
                </v-tooltip>
              </template>
              <template v-slot:usersflag="{ item }">
                <v-icon v-if="item.allusersset" color="success">mdi-check-circle</v-icon>
                <v-icon v-else color="error">mdi-close-circle</v-icon>
              </template>
              <template v-slot:materialityflag_actual="{ item }">
                <v-icon v-if="item.materialityset_actual" color="success">mdi-check-circle</v-icon>
                <v-icon v-else color="error">mdi-close-circle</v-icon>
              </template>
              <template v-slot:materialityflag_forecast="{ item }">
                <v-icon v-if="item.materialityset_forecast" color="success">mdi-check-circle</v-icon>
                <v-icon v-else color="error">mdi-close-circle</v-icon>
              </template>
              <template v-slot:action="{ item }">
                <div class="d-flex align-center">
                  <v-tooltip bottom content-class="tooltip-bottom">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon small v-bind="attrs" v-on="on" color="" @click="getRUDetail(item._id)">
                        <v-icon>mdi-eye</v-icon>
                      </v-btn>
                    </template>
                    <span>View Details</span>
                  </v-tooltip>
                  <v-tooltip v-if="$nova.hasRight('commentary_permission_user', 'read')" bottom
                    content-class="tooltip-bottom">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon small v-bind="attrs" v-on="on" color="primaryicon" @click="enableDisableWorkflow(item)">
                        <v-icon>mdi-pencil</v-icon>
                      </v-btn>
                    </template>
                    <span>Enable/Disable workflow</span>
                  </v-tooltip>
                  <v-tooltip v-if="$nova.hasRight('commentary_permission_user', 'read')" bottom
                    content-class="tooltip-bottom-right">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon small color="secondaryicon" v-bind="attrs" v-on="on"
                        @click="enableDisablebulkapprove(item)">
                        <v-icon>mdi-pencil</v-icon>
                      </v-btn>
                    </template>
                    <span>Enable/Disable Bulk Approve</span>
                  </v-tooltip>
                </div>
              </template>
              <template v-slot:role="{item}">
                <div class="d-flex align-center">
                  <span v-if="item.approver_list.indexOf(selectedUserId) > -1">Local Approver</span>
                  <span v-else-if="item.preparer_list.indexOf(selectedUserId) > -1">Local Preparer</span>
                  <span v-else-if="item.regional_approver_list.indexOf(selectedUserId) > -1">Regional Approver</span>
                  <span v-else-if="item.manager_list.indexOf(selectedUserId) > -1">Manager</span>
                  <span v-else>View only</span>
                </div>
              </template>
            </lb-datatable_pagination>
            <div v-else class="title grey--text pa-5 text-center">
              No Data Found
            </div>
          </div>
        </v-card-text>
      </v-card>

      <lb-dialog v-model="rudetaildialog" heading="Reporting Unit Detail" width="600" :loading="rudetailloading">
        <template v-slot:body>
          <div>
            <div class="subtitle-1">{{rudetail.code}} - {{rudetail.name}}</div>
            <div class="mb-5">
              <v-chip small label class="mr-1">{{rudetail.network_detail?.name}}</v-chip>
              <v-chip small label class="mr-1">{{rudetail.region_detail?.name}}</v-chip>
              <v-chip small label class="mr-1">{{rudetail.country_detail?.name}}</v-chip>
            </div>
            <div class="mb-4">
              <div class="info--text pl-2 mb-1 border-left-info">Local Preparer</div>
              <div class="ml-3">
                <template v-if="(rudetail.submitter_detail || []).length > 0">
                  <v-chip v-for="(v,k) in rudetail.submitter_detail" text :key="k" label small
                    :color="(userid === v._id)?'primary':'grey lighten-4'" class="mr-1 mb-1">
                    <v-icon class="mr-1">mdi-account</v-icon>
                    {{v.name}} - {{v.email}}
                  </v-chip>
                </template>
                <div class="error--text" v-else>No Users assigned</div>
              </div>
            </div>
            <div class="mb-4">
              <div class="warning--text pl-2 mb-1 border-left-warning">Local Approver</div>
              <div class="ml-3">
                <template v-if="(rudetail.approver_detail || []).length > 0">
                  <v-chip v-for="(v,k) in rudetail.approver_detail" text :key="k" label small
                    :color="(userid === v._id)?'primary':'grey lighten-4'" class="mr-1 mb-1">
                    <v-icon class="mr-1">mdi-account</v-icon>
                    {{v.name}} - {{v.email}}
                  </v-chip>
                </template>
                <div class="error--text" v-else>No Users assigned</div>
              </div>
            </div>
            <div class="mb-4">
              <div class="success--text pl-2 mb-1 border-left-success">Regional Approver</div>
              <div class="ml-3">
                <template v-if="(rudetail.regional_approver_detail || []).length > 0">
                  <v-chip v-for="(v,k) in rudetail.regional_approver_detail" text :key="k" label small
                    :color="(userid === v._id)?'primary':'grey lighten-4'" class="mr-1 mb-1">
                    <v-icon class="mr-1">mdi-account</v-icon>
                    {{v.name}} - {{v.email}}
                  </v-chip>
                </template>
                <div class="error--text" v-else>No Users assigned</div>
              </div>
            </div>
            <div class="mb-4">
              <div class="grey--text pl-2 mb-1 border-left-grey">Manager</div>
              <div class="ml-3">
                <template v-if="(rudetail.manager_detail || []).length > 0">
                  <v-chip v-for="(v,k) in rudetail.manager_detail" text :key="k" label small
                    :color="(userid === v._id)?'primary':'grey lighten-4'" class="mr-1 mb-1">
                    <v-icon class="mr-1">mdi-account</v-icon>
                    {{v.name}} - {{v.email}}
                  </v-chip>
                </template>
                <div class="error--text" v-else>No Users assigned</div>
              </div>
            </div>
          </div>
        </template>
      </lb-dialog>

      <lb-dialog v-model="enabledisabledialog" heading="Enable/Disable workflow" width="600"
        :loading="enabledisableloading">
        <template v-slot:body>
          <div>
            <div class="subtitle-1">{{enabledisableworkflowdata.code}} - {{enabledisableworkflowdata.name}}</div>
            <div class="mb-5">
              <v-chip small label class="mr-1">{{enabledisableworkflowdata.network}}</v-chip>
              <v-chip small label class="mr-1">{{enabledisableworkflowdata.region}}</v-chip>
              <v-chip small label class="mr-1">{{enabledisableworkflowdata.country}}</v-chip>
            </div>
            <div class="subtitle-1">Proforma</div>
            <div class="d-flex">
              <v-checkbox dense hide-details label="Local Control"
                v-model="enabledisableworkflowdata.localcontrol_proforma"></v-checkbox>
              <v-checkbox dense class="ml-4" hide-details label="Local Management"
                v-model="enabledisableworkflowdata.localmanagement_proforma"></v-checkbox>
            </div>
            <div class="d-flex">
              <v-checkbox dense hide-details label="Regional Control"
                v-model="enabledisableworkflowdata.regionalcontrol_proforma"></v-checkbox>
              <v-checkbox dense class="ml-4" hide-details label="Regional Management"
                v-model="enabledisableworkflowdata.regionalmanagement_proforma"></v-checkbox>
            </div>
            <v-divider></v-divider>
            <div class="subtitle-1 mt-4">Forecast</div>
            <div class="d-flex">
              <v-checkbox dense hide-details label="Local Control"
                v-model="enabledisableworkflowdata.localcontrol_forecast"></v-checkbox>
              <v-checkbox dense class="ml-4" hide-details label="Local Management"
                v-model="enabledisableworkflowdata.localmanagement_forecast"></v-checkbox>
            </div>
            <div class="d-flex">
              <v-checkbox dense hide-details label="Regional Control"
                v-model="enabledisableworkflowdata.regionalcontrol_forecast"></v-checkbox>
              <v-checkbox dense class="ml-4" hide-details label="Regional Management"
                v-model="enabledisableworkflowdata.regionalmanagement_forecast"></v-checkbox>
            </div>
          </div>
          <div class="mt-6">
            <b>Note:</b> If, as a result of this submission, a commentary cycle crosses control threshold either at
            local or regional level, then this functionality will not revert the RU level approval already done for such
            commentary cycle.
          </div>
        </template>
        <template v-slot:actions>
          <v-spacer></v-spacer>
          <v-btn small color="success" class="" @click="enableDisableWorkflowSubmit()">Update</v-btn>
        </template>
      </lb-dialog>

      <lb-dialog v-model="bulkenableworkflowdialogue" heading="Bulk Enable/Disable workflow" width="600"
        :loading="enabledisableloading">
        <template v-slot:body>
          <div>
            <div class="subtitle-1">Proforma</div>
            <div class="d-flex">
              <v-checkbox dense hide-details label="Local Control"
                v-model="enabledisableworkflowdata.localcontrol_proforma"></v-checkbox>
              <v-checkbox dense class="ml-4" hide-details label="Local Management"
                v-model="enabledisableworkflowdata.localmanagement_proforma"></v-checkbox>
            </div>
            <div class="d-flex">
              <v-checkbox dense hide-details label="Regional Control"
                v-model="enabledisableworkflowdata.regionalcontrol_proforma"></v-checkbox>
              <v-checkbox dense class="ml-4" hide-details label="Regional Management"
                v-model="enabledisableworkflowdata.regionalmanagement_proforma"></v-checkbox>
            </div>
            <v-divider></v-divider>
            <div class="subtitle-1 mt-4">Forecast</div>
            <div class="d-flex">
              <v-checkbox dense hide-details label="Local Control"
                v-model="enabledisableworkflowdata.localcontrol_forecast"></v-checkbox>
              <v-checkbox dense class="ml-4" hide-details label="Local Management"
                v-model="enabledisableworkflowdata.localmanagement_forecast"></v-checkbox>
            </div>
            <div class="d-flex">
              <v-checkbox dense hide-details label="Regional Control"
                v-model="enabledisableworkflowdata.regionalcontrol_forecast"></v-checkbox>
              <v-checkbox dense class="ml-4" hide-details label="Regional Management"
                v-model="enabledisableworkflowdata.regionalmanagement_forecast"></v-checkbox>
            </div>
          </div>
          <div class="mt-6">
            <b>Note:</b> If, as a result of this submission, a commentary cycle crosses control threshold either at
            local or regional level, then this functionality will not revert the RU level approval already done for such
            commentary cycle.
          </div>
        </template>
        <template v-slot:actions>
          <v-spacer></v-spacer>
          <v-btn small color="success" class="" @click="enableDisableBulkWorkflowSubmit()">Update</v-btn>
        </template>
      </lb-dialog>

      <lb-dialog v-model="allaccessdownloaddialog" :loading="allaccessdownloadloading"
        heading="All access data download" width="600">
        <template v-slot:body>
          <div>
            <!-- <lb-filter :key="allaccessdownloaddialog ? 'filter-' + Date.now() : '' :filterheaders="generateFileFilterOptions" :searchOption="false" :generateFileOption="true" :isFileGenarating="false" generateFileText="Generate latest" @genarateFile="generateFile" /> -->
             <lb-filter :key="allaccessdownloaddialog ? 'filter-' + Date.now() : ''" 
                   :filterheaders="generateFileFilterOptions" 
                   :searchOption="false" 
                   :generateFileOption="true" 
                   :isFileGenarating="false" 
                   generateFileText="Generate latest" 
                   @genarateFile="generateFile"/>
            <!-- <div class="d-flex align-center">
              <v-spacer />
              <v-btn small color="success" @click="downloadAllAccess()">Generate latest</v-btn>
            </div> -->
            <div v-if="allaccessdownloadlist.length === 0" class="title grey--text text--lighten-1 ma-4 text-center">No
              downloads</div>
            <div v-else>
              <div class="mb-2 font-weight-bold">Previous downloads</div>
              <div class="hoverable" v-for="(v,k) in allaccessdownloadlist" :key="k">
                <div class="d-flex align-center px-2 py-1">
                  <div>{{$nova.formatDateTime(v.created_at)}}</div>
                  <v-spacer />
                  <v-tooltip bottom content-class="tooltip-bottom" v-if="v.stage===1">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn class="mx-0 px-0" icon small v-bind="attrs" v-on="on"
                        @click.stop="downloadAllAccess(v._id)">
                        <v-icon>mdi-download</v-icon>
                      </v-btn>
                    </template>
                    <span>Download</span>
                  </v-tooltip>
                  <v-chip small v-if="v.stage === 1" color="success" label>Ready</v-chip>
                  <v-chip small v-else-if="v.stage === 0 || v.stage === 4" color="info" label>Generating</v-chip>
                  <div v-else>
                    <v-chip small color="error" label v-if='v.errormessage === ""'>Failed</v-chip>
                    <v-tooltip right content-class="tooltip-right" v-else>
                      <template v-slot:activator="{ on }">
                        <v-btn class="mx-3 px-0" icon v-on="on">
                          <v-chip small color="error" label>Failed</v-chip>
                        </v-btn>
                      </template>
                      <span>{{v.errormessage}}</span>
                      <span></span>
                    </v-tooltip>
                  </div>
                  <v-tooltip bottom content-class="tooltip-bottom">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn class="mx-0 px-0" icon small v-bind="attrs" v-on="on"
                        @click.stop="viewFileGeneratedDetails(v)">
                        <v-icon>mdi-eye</v-icon>
                      </v-btn>
                    </template>
                    <span>View Details</span>
                  </v-tooltip>
                </div>
                <v-divider />
              </div>
            </div>
          </div>
        </template>
      </lb-dialog>

      <lb-dialog v-model="allrumaterialitydownloaddialog" :loading="allrumaterialitydownloadloading"
        heading="All RU Materiality data download" width="800">
        <template v-slot:body>
          <div  >
            <div class="d-flex" max-height="400">
            <!-- <lb-filter :key="allaccessdownloaddialog ? 'filter-' + Date.now() : '' :filterheaders="generateFileFilterOptions" :searchOption="false" :generateFileOption="true" :isFileGenarating="false" generateFileText="Generate latest" @genarateFile="generateFile" /> -->
             <div class="d-flex justify-center filter-hover">
              <div class="border-on d-flex shadow-on radius-all">
                <div class="text-center px-4 py-2 hoverable" >
                  <div class="grey--text text--darken-1 line-1">Cycle Type</div>
                  <div class="font-weight-bold">
                    <v-menu max-width="150" offset-y :close-on-content-click="false" rounded="lg">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn icon x-small v-bind="attrs" v-on="on">
                          <v-icon x-small class="grey--text text--lighten-1" v-if="selectedCycleTypeToGenerate !==''">mdi-filter</v-icon>
                          <v-icon x-small class="black--text text--lighten-0" v-else>mdi-filter</v-icon>
                        </v-btn>
                      </template>
                      <v-card max-height="400">
                        <v-card-text class="pa-0 ma-0 pt-0 scroll">
                          <v-radio-group v-model="selectedCycleTypeToGenerate">
                            <template v-for="(option, k) in [{name:'Actual',_id:'actual'},{name:'Forecast',_id:'forecast'}]">
                              <v-radio :key="k" :label="option.name" :value="option._id"></v-radio>
                            </template>
                          </v-radio-group>
                        </v-card-text>
                      </v-card>
                    </v-menu>
                  </div>
                </div>
              </div>
              </div>
                <div class="d-flex justify-center filter-hover">
              <div class="border-on d-flex shadow-on radius-all">
                <div class="text-center px-4 py-2 hoverable" >
                  <div class="grey--text text--darken-1 line-1">Year</div>
                  <div class="font-weight-bold">
                    <v-menu max-width="150" offset-y :close-on-content-click="false" rounded="lg">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn icon x-small v-bind="attrs" v-on="on">
                          <v-icon x-small class="grey--text text--lighten-1" v-if="selectedYearToGenerate !==''">mdi-filter</v-icon>
                          <v-icon x-small class="black--text text--lighten-0" v-else>mdi-filter</v-icon>
                        </v-btn>
                      </template>
                      <v-card max-height="400">                       
                      <v-card-text class="pa-0 ma-0 pt-0 scroll">
                        <!-- <v-checkbox
                          v-for="(option, k) in years"
                          :key="k" class="pa-2 mt-0 pt-0" dense :multiple="false" :label="option.name" :value="option.name"
                          hide-details v-model="selectedYearToGenerate" /> -->
                          <v-radio-group v-model="selectedYearToGenerate">
                            <template v-for="(option, k) in years">
                              <v-radio :key="k" :label="option.name" :value="option.name"></v-radio>
                            </template>
                          </v-radio-group>
                      </v-card-text>
                      </v-card>
                    </v-menu>
                  </div>
                </div>
                <div class="text-center px-4 py-2 hoverable" >
                  <div class="grey--text text--darken-1 line-1">Months</div>
                  <div class="font-weight-bold">
                    <v-menu max-width="250" offset-y :close-on-content-click="false" rounded="lg">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn icon x-small v-bind="attrs" v-on="on">
                          <v-icon x-small class="grey--text text--lighten-1" v-if="selectedCycleTypeToGenerate !==''">mdi-filter</v-icon>
                          <v-icon x-small class="black--text text--lighten-0" v-else>mdi-filter</v-icon>
                        </v-btn>
                      </template>
                      <v-card max-height="400">
                        <div class="mt-2 ml-3 d-flex mb-2" v-if="selectedMonthToGenerate.length > 0">
                          {{ selectedMonthToGenerate.length }} Selected Items
                          <v-spacer></v-spacer>
                          <div class="mr-8">
                            <v-btn class="" x-small @click="selectedMonthToGenerate = []">
                              Clear Selections
                            </v-btn>
                          </div>
                        </div>
                        <v-card-text class="pa-0 ma-0 pt-2 scroll">
                          <v-checkbox
                            v-for="(option, k) in months"
                            :key="k" class="pa-2 mt-0 pt-0" dense multiple :label="option.text" :value="option.value"
                            hide-details v-model="selectedMonthToGenerate" />
                        </v-card-text>
                      </v-card>
                    </v-menu>
                  </div>
                </div>
              </div>
            </div>
            <lb-filter :key="allrumaterialitydownloaddialog ? 'filter-' + Date.now() : ''" 
                   :filterheaders="generateMaterialityFileFilterOptions" 
                   :searchOption="false" 
                   :generateFileOption="true" 
                   :isFileGenarating="false" 
                   generateFileText="Generate latest" 
                   @genarateFile="generateFile"/>
                   </div>
            <!-- <div class="d-flex align-center">
              <v-spacer />
              <v-btn small color="success" @click="downloadAllAccess()">Generate latest</v-btn>
            </div> -->
            <div v-if="allrumaterialitydownloadlist.length === 0" class="title grey--text text--lighten-1 ma-4 text-center">No
              downloads</div>
            <div v-else>
              <div class="mb-2 font-weight-bold">Previous downloads</div>
              <div class="hoverable" v-for="(v,k) in allrumaterialitydownloadlist" :key="k">
                <div class="d-flex align-center px-2 py-1">
                  <div>{{$nova.formatDateTime(v.created_at)}}</div>
                  <v-spacer />
                  <v-tooltip bottom content-class="tooltip-bottom" v-if="v.stage===1">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn class="mx-0 px-0" icon small v-bind="attrs" v-on="on"
                        @click.stop="downloadAllRUMateriality(v._id)">
                        <v-icon>mdi-download</v-icon>
                      </v-btn>
                    </template>
                    <span>Download</span>
                  </v-tooltip>
                  <v-chip small v-if="v.stage === 1" color="success" label>Ready</v-chip>
                  <v-chip small v-else-if="v.stage === 0 || v.stage === 4" color="info" label>Generating</v-chip>
                  <div v-else>
                    <v-chip small color="error" label v-if='v.errormessage === ""'>Failed</v-chip>
                    <v-tooltip right content-class="tooltip-right" v-else>
                      <template v-slot:activator="{ on }">
                        <v-btn class="mx-3 px-0" icon v-on="on">
                          <v-chip small color="error" label>Failed</v-chip>
                        </v-btn>
                      </template>
                      <span>{{v.errormessage}}</span>
                      <span></span>
                    </v-tooltip>
                  </div>
                  <v-tooltip bottom content-class="tooltip-bottom">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn class="mx-0 px-0" icon small v-bind="attrs" v-on="on"
                        @click.stop="viewFileGeneratedDetails(v)">
                        <v-icon>mdi-eye</v-icon>
                      </v-btn>
                    </template>
                    <span>View Details</span>
                  </v-tooltip>
                </div>
                <v-divider />
              </div>
            </div>
          </div>
        </template>
      </lb-dialog>

      <lb-dialog v-model="viewfiledetailsdialog" heading="Details" width="500">
        <template v-slot:body>
          <div>
            
            <v-chip class="mb-3" label small :color="selectedFileItem.__codecolor"> Report Details </v-chip>
            <div>
              <v-simple-table dense scrollable>
                <!-- <thead>
                  <tr>
                    <th scope="key">Key</th>
                    <th scope="value">Value</th>
                  </tr>
                </thead> -->
                <tbody>
                  <tr>
                    <td>
                      Generated Time
                    </td>
                    <td>
                      {{$nova.formatDateTime(selectedFileItem.created_at)}}
                    </td>
                  </tr>
                  <template v-for="(v,k) in selectedFileItem.report_details || {}">
                    <tr :key="k" v-if="k !== '_id'">
                      <td>{{ k.charAt(0).toUpperCase() + k.slice(1)  }}</td>
                      <td>
                        <span>{{ v }}</span>
                      </td>
                    </tr>
                  </template>
                </tbody>
              </v-simple-table>
            </div>
            <v-chip class="mt-3 mb-3" label small :color="selectedFileItem.__codecolor">Filter</v-chip>
            <div>
              <v-simple-table dense>
                <thead>
                  <tr>
                    <th scope="key">Type</th>
                    <th scope="value">Selected Options</th>
                  </tr>
                </thead>
                <tbody>
                  <template v-for="(v,k) in selectedFileItem.filter || {}">
                    <tr :key="k+'view'">
                      <td>{{ k.charAt(0).toUpperCase() + k.slice(1) }}</td>
                      <td v-if="v.length>0 && k !== 'cycletype' && k !== 'year'">
                        
                        <template v-for="(item,key) in v || {}">
                          <div :key="key+item">
                            <!-- {{allDataidmap[k]?.[item] || 'UN'}} -->
                              <span>{{ allDataidmap[k]?.[item] }}</span>
                          </div>
                        </template>
                      </td>
                      <td v-else-if="k === 'cycletype' || k === 'year'">
                        {{v}}
                      </td>
                      <td v-else>
                        All
                      </td>
                    </tr>
                    <v-divider :key="k" col="2"/>
                  </template>
                </tbody>
              </v-simple-table>
            </div>
          </div>
        </template>
      </lb-dialog>

      <lb-dialog v-model="enabledisablebulkapprovedialog" heading="Enable/Disable Bulk Approve" width="600"
        :loading="enabledisableloading">
        <template v-slot:body>
          <div>
            <div class="subtitle-1">{{ enabledisablebulkapprovedata.code }} - {{ enabledisablebulkapprovedata.name }}
            </div>
            <div class="mb-5">
              <v-chip small label class="mr-1">{{ enabledisablebulkapprovedata.network }}</v-chip>
              <v-chip small label class="mr-1">{{ enabledisablebulkapprovedata.region }}</v-chip>
              <v-chip small label class="mr-1">{{ enabledisablebulkapprovedata.country }}</v-chip>
            </div>
            <div class="d-flex">
              <v-checkbox dense hide-details label="Bulk Approve Control"
                v-model="enabledisablebulkapprovedata.bulkapprove_control" :disabled="!enabledisablebulkapprovedata.localcontrol_proforma && !enabledisablebulkapprovedata.localcontrol_forecast && !enabledisablebulkapprovedata.regionalcontrol_proforma && !enabledisablebulkapprovedata.regionalcontrol_forecast"></v-checkbox>
            </div>
          </div>
        </template>
        <template v-slot:actions>
          <div v-if="!enabledisablebulkapprovedata.localcontrol_proforma && !enabledisablebulkapprovedata.localcontrol_forecast && !enabledisablebulkapprovedata.regionalcontrol_proforma && !enabledisablebulkapprovedata.regionalcontrol_forecast">
            <span  class='error--text'>Control workflows are disabled</span>
          </div>
          <v-spacer></v-spacer>
          <v-btn small color="success" class="" @click="enableDisablebulkapproveSubmit()" v-if="enabledisablebulkapprovedata.localcontrol_proforma || enabledisablebulkapprovedata.localcontrol_forecast || enabledisablebulkapprovedata.regionalcontrol_proforma || enabledisablebulkapprovedata.regionalcontrol_forecast">Update</v-btn>
        </template>
      </lb-dialog>

      <lb-dialog v-model="bulkenablebulkapprovedialogue" heading="Enable/Disable Bulk Approve" width="600"
        :loading="enabledisableloading">
        <template v-slot:body>
          <div>
            <div class="d-flex">
              <v-checkbox dense hide-details label="Bulk Approve Control"
                v-model="enabledisablebulkapprovedata.bulkapprove_control"></v-checkbox>
            </div>
          </div>
        </template>
        <template v-slot:actions>
          <v-spacer></v-spacer>
          <v-btn small color="success" class="" @click="enableDisablebulkapproveSubmit()">Update</v-btn>
        </template>
      </lb-dialog>

      <lb-dialog v-model="warnGenerateFile" heading="Warning" width="600"
        :loading="allaccessdownloadloading">
        <template v-slot:body>
          <div>
            <div >
              <span class="mb-2 in-line">The filters applied for generating the user access details may result in large volumes of data extraction and could take significant time to generate the report. 
                It would be better to apply relevant filters before generating the user access report.</span>
                <br/>
              <dib class='mt-3 font-weight-bold'>Do you still wish to proceed with this request?</dib>
            </div>
          </div>
        </template>
        <template v-slot:actions>
          <v-spacer></v-spacer>
          <v-btn small v-if="allrumaterialitydownloaddialog===true" color="success" class="" @click="downloadAllRUMateriality(null,{})">Proceed</v-btn>
          <v-btn small v-else color="success" class="" @click="downloadAllAccess(null,{})">Proceed</v-btn>
        </template>
      </lb-dialog>
    </div>
  </div>
</template>

<script>
// import cfilter from './Filter'
export default {
  name: 'reportingunits',
  // props: ['props'],
  data: function () {
    return {
      tab:0,
      right: "commentary_reportingunit",
      meta:{total:0},
      loading: false,
      data: [],
      userlist: {},
      userlistarr: {},
      useremailidmap: {},
      addeditrudialog: false,
      addeditruloading: false,
      addeditrudata: {},
      userdatachange_submitter: [],
      userdatachange_approver: [],
      userdatachange_regional_approver: [],
      userdatachange_manager: [],
      addeditruerror: {},

      adduserdialog: false,
      addusertype: '',
      adduservalue: '',
      rucodenamemap: {},
      rucodeidmap: {},
      allDataidmap:{},
      bulksubmitrudialog: false,
      bulksubmitruloading: false,
      bulksubmitrufileerrorflag: false,
      bulksubmitrufile: null,
      bulksubmitrufiledata: [],
      bulksubmitrufileerror: [],
      bulksubmitrufilecolumns: ["rucode", "runame", "l_preparer", "l_approver", "r_approver", "manager"],
      fileprocessing: {},

      enabledisableworkflowdata: {},
      enabledisabledialog: false,
      enabledisableloading: false,

      bulksubmit: false,
      filesubmit: false,
      pendingrequestlist: [],
      pendingdialog: false,
      pendingloading: false,
      formatedData: [],
      headers: [
        {
          value: "indexno",
          text: "#",
          sortable: false,
          filterable: false,
        },
        {
          value: "code",
          text: "RU Code",
          sortable: true,
          filterable: false,
          lock: true,
        },
        {
          value: "name",
          text: "Name",
          sortable: true,
          filterable: false,
          lock: true,
        },
        {
          value: "network",
          text: "Network",
          sortable: true,
          filterable: false,
          lock: true,
        },
        {
          value: "region",
          text: "Region",
          sortable: true,
          filterable: false,
          lock: true,
        },
        {
          value: "country",
          text: "Country",
          sortable: true,
          filterable: false,
          lock: true,
        },
        {
          value: "usersflag",
          text: "All Users Set?",
          sortable: false,
          lock: true,
          visible: false,
          alignment: "text-center",
          filterable: false,
        },
        {
          value: "materialityflag_actual",
          text: "Actual Materiality Set?",
          sortable: false,
          lock: true,
          visible: false,
          alignment: "text-center",
          filterable: false,
        },
        {
          value: "materialityflag_forecast",
          text: "Forecast Materiality Set?",
          sortable: false,
          lock: true,
          visible: false,
          alignment: "text-center",
          filterable: false,
        },
        {
          value: "action",
          text: "Action",
          sortable: false,
          datatype: "action",
          lock: true,
          visible: false,
          alignment: "text-center",
        },
      ],
      rudetail: {},
      rudetailloading: false,
      rudetaildialog: false,
      userid: '',
      allaccessdownloaddialog: false,
      allaccessdownloadloading: false,
      allaccessdownloadlist: [],
      allRUDownloadPeriod: null,
      enabledisablebulkapprovedialog: false,
      enabledisablebulkapprovedata:{},
      bulkenableworkflowdialogue:false,
      bulkenablebulkapprovedialogue: false,
      page: 0,
      filterOptions:[],
      previousFilter:{},
      generateFileFilterOptions:[],
      warnGenerateFile:false,
      viewfiledetailsdialog:false,
      selectedFileItem:{},
      allrumaterialitydownloadlist:[],
      allrumaterialitydownloadloading:false,
      allrumaterialitydownloaddialog:false,
      generateMaterialityFileFilterOptions:[],
      selectedYearToGenerate:new Date().getFullYear(),
      selectedMonthToGenerate:1,
      selectedCycleTypeToGenerate:"actual",
      months:[
        {value:1,text:"January"},
        {value:2,text:"February"},
        {value:3,text:"March"},
        {value:4,text:"April"},
        {value:5,text:"May"},
        {value:6,text:"June"},
        {value:7,text:"July"},
        {value:8,text:"August"},
        {value:9,text:"September"},
        {value:10,text:"October"},
        {value:11,text:"November"},
        {value:12,text:"December"}
      ],
      years:[
        {name:2022},
        {name:2023},
        {name:2024},
        {name:2025},
      ]
    }
  },
  components: {
    // cfilter
  },
  props:{
    isViewOnly:{
      default:false,
      required:false,
      type:Boolean
    },
    selectedUserId:{
      default:undefined,
      required:false,
      type:String
    }
  },
  activated() {
    // this.refreshData();
  },
  created() {
    this.getFilterOptionsData();
    // this.refreshData();
  },
  methods: {
    refreshData() {
      this.userid = this.$store.state.user.id;
      this.formatedData=[]
      this.getData();
    },
    setPendingLoading(v) {
      this.pendingloading = v;
    },
    getFilterOptionsData(){
      this.loading=true
      let filter={"type":"all_rus","sync_type": "auto"}
      if(this.selectedUserId !== undefined){
        filter.user_id = this.selectedUserId 
      }
      this.axios.post("/v2/varianceanalysis/reportingunit/getRUFilters", {"filter":filter}).then(dt => {
        if (dt.data.status === "success") {
          let hierarchyData = dt.data.data || {}
          this.filterOptions=[
                  
                  // {
                  //   value: 'name',
                  //   key: 'runame',
                  //   data: hierarchyData.name.map((item)=>({_id:item,"name":item})),
                  //   name: "RU Name"
                  // },
                  {
                    value: 'name',
                    key: 'network',
                    name: "Network",
                    data: hierarchyData.network_detail
                  },
                  {
                    value: 'name',
                    key: 'region',
                    data: hierarchyData.region_detail,
                    name: "Region"
                  },
                  {
                    value: 'name',
                    key: 'country',
                    name: "Country",
                    data: hierarchyData.country_detail
                  },
                  {
                    value: 'allusersset',
                    key: 'allusersset',
                    data: [
                      {
                        allusersset:'Yes',
                        _id:'yes'
                      },
                      {
                        allusersset: 'No',
                        _id:'no'
                      }
                    ],
                    name: "All Users Set"
                  },
                  {
                    value: 'code',
                    key: 'code',
                    name: "RU Code",
                    data: (hierarchyData?.name || []).map((id) => ({
                            _id: id.code,
                            code: id.code+" - "+id.name
                        })),
                  }
                  // {
                  //   value: 'actualmaterialityset',
                  //   key: 'actualmaterialityset',
                  //   data: [
                  //     {
                  //       actualmaterialityset: 'Yes',
                  //       _id:'yes'
                  //     },
                  //     {
                  //       actualmaterialityset: 'No',
                  //       _id:'no'
                  //     }
                  //   ],
                  //   name: "Actual Materiality Set"
                  // },
                  // {
                  //   value: 'forecastmaterialityset',
                  //   key: 'forecastmaterialityset',
                  //   data: [
                  //     {
                  //       forecastmaterialityset: 'Yes',
                  //       _id:'yes'
                  //     },
                  //     {
                  //       forecastmaterialityset: 'No',
                  //       _id:'no'
                  //     }
                  //   ],
                  //   name: "Forecast Materiality Set"
                  // },
                ]
                this.generateFileFilterOptions=[
                  {
                    value: 'role',
                    key: 'role',
                    name: "Role",
                    defaultSelectAll:true,
                    data: [
                      {
                        role: 'Super Admin',
                        _id: 'sa'
                      },
                      {
                        role: 'Tool Admin Preparer',
                        _id: 'tap'
                      },
                      {
                        role: 'Tool Admin Approver',
                        _id: 'taa'
                      },
                      {
                        role: 'Local Preparer',
                        _id: 'local_Preparer'
                      },
                      {
                        role: 'Local Approver',
                        _id: 'local_approver'
                      },
                      {
                        role: 'Regional Approver',
                        _id: 'regional_approver'
                      },
                      {
                        role: 'Manager',
                        _id: 'manager'
                      },
                      {
                        role: 'General User',
                        _id: 'general_user'
                      },
                      {
                        role: 'View Only',
                        _id: 'view_only_user'
                      }
                    ]
                  },
                  
                  {
                    value: 'name',
                    key: 'network',
                    name: "Network",
                    defaultSelectAll:true,
                    data: hierarchyData.network_detail
                  },
                  {
                    value: 'name',
                    key: 'region',
                    data: hierarchyData.region_detail,
                    name: "Region",
                    defaultSelectAll:true,
                  },
                  {
                    value: 'name',
                    key: 'country',
                    name: "Country",
                    defaultSelectAll:true,
                    data: hierarchyData.country_detail
                  },
                  {
                    value: 'code',
                    key: 'code',
                    name: "RU Code",
                    defaultSelectAll:true,
                    data: (hierarchyData?.name || []).map((id) => ({
                            _id: id.code,
                            code: id.code+" - "+id.name
                        })),
                  },
                ];
                this.generateMaterialityFileFilterOptions=[
                    {
                      value: 'name',
                      key: 'network',
                      name: "Network",
                      defaultSelectAll:false,
                      data: hierarchyData.network_detail
                    },
                    {
                      value: 'name',
                      key: 'region',
                      data: hierarchyData.region_detail,
                      name: "Region",
                      defaultSelectAll:true,
                    },
                    {
                      value: 'name',
                      key: 'country',
                      name: "Country",
                      defaultSelectAll:true,
                      data: hierarchyData.country_detail
                    },
                    {
                      value: 'code',
                      key: 'code',
                      name: "RU Code",
                      defaultSelectAll:true,
                      data: (hierarchyData?.name || []).map((id) => ({
                              _id: id.code,
                              code: id.code+" - "+id.name
                          })),
                    },
                ];
          this.meta={total: hierarchyData?.name.length}
          this.generateFileFilterOptions.forEach(item=>{
            this.allDataidmap[item.key] = {}
            item['data'].forEach(eachData=>{
              this.allDataidmap[item.key][eachData._id] = eachData[item.value];
            })
            this.allDataidmap['month'] ={
                                          1: "January",
                                          2: "February",
                                          3: "March",
                                          4: "April",
                                          5: "May",
                                          6: "June",
                                          7: "July",
                                          8: "August",
                                          9: "September",
                                          10: "October",
                                          11: "November",
                                          12: "December"
                                        };
          })
          if(this.isViewOnly){
            this.headers= [
              {
                value: "indexno",
                text: "#",
                sortable: false,
                filterable: false,
              },
              {
                value: "code",
                text: "RU Code",
                sortable: true,
                filterable: false,
                lock: true,
              },
              {
                value: "name",
                text: "Name",
                sortable: true,
                filterable: false,
                lock: true,
              },
              {
                value: "network",
                text: "Network",
                sortable: true,
                filterable: false,
                lock: true,
              },
              {
                value: "region",
                text: "Region",
                sortable: true,
                filterable: false,
                lock: true,
              },
              {
                value: "country",
                text: "Country",
                sortable: true,
                filterable: false,
                lock: true,
              },
              {
                value: "role",
                text: "Role",
                sortable: true,
                filterable: false,
                lock: true,
              }
            ];
            this.filterOptions=[
                // {
                //   value: 'role',
                //   key: 'role',
                //   name: "Role",
                //   defaultSelectAll:true,
                //   data: [
                //     {
                //       role: 'Super Admin',
                //       _id: 'sa'
                //     },
                //     {
                //       role: 'Tool Admin Preparer',
                //       _id: 'tap'
                //     },
                //     {
                //       role: 'Tool Admin Approver',
                //       _id: 'taa'
                //     },
                //     {
                //       role: 'Local Preparer',
                //       _id: 'local_Preparer'
                //     },
                //     {
                //       role: 'Local Approver',
                //       _id: 'local_approver'
                //     },
                //     {
                //       role: 'Regional Approver',
                //       _id: 'regional_approver'
                //     },
                //     {
                //       role: 'Manager',
                //       _id: 'manager'
                //     },
                //     {
                //       role: 'General User',
                //       _id: 'general_user'
                //     },
                //     {
                //       role: 'View Only',
                //       _id: 'view_only_user'
                //     }
                //   ]
                // },
                
                {
                  value: 'name',
                  key: 'network',
                  name: "Network",
                  defaultSelectAll:true,
                  data: hierarchyData.network_detail
                },
                {
                  value: 'name',
                  key: 'region',
                  data: hierarchyData.region_detail,
                  name: "Region",
                  defaultSelectAll:true,
                },
                {
                  value: 'name',
                  key: 'country',
                  name: "Country",
                  defaultSelectAll:true,
                  data: hierarchyData.country_detail
                },
                {
                  value: 'code',
                  key: 'code',
                  name: "RU Code",
                  defaultSelectAll:true,
                  data: (hierarchyData?.name || []).map((id) => ({
                          _id: id.code,
                          code: id.code+" - "+id.name
                      })),
                },
              ]
      }
          this.refreshData()
        } else throw new Error("Error reading data");
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
        this.loaing = false
        
      }).finally(() => {
      })
    },
    getData(d=this.page) {
      this.loading = true;
      this.pendingrequestlist = [];
      
      this.previousFilter.page =  d?.page || d;
      this.previousFilter.limit= 50
      if(this.previousFilter.allusersset === -1){
        delete this.previousFilter.allusersset
      }
      if(this.selectedUserId !== undefined){
        this.previousFilter.user_id = this.selectedUserId 
      }
      this.axios.post("/v2/varianceanalysis/reportingunit/getmyrus", { filter: this.previousFilter}).then(dt => {
        if(dt.data.status === "success") {
            this.meta = (dt.data || {}).meta || this.meta || {total:0};
            this.formatedData = [...this.formatedData, ...this.formatData(dt.data.data)];
        }
        else throw new Error ("Error reading data");
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
      }).finally(() => {
        this.loading = false;
      })
    },

    getRefreshData(d){
      if(d.reload === true){
        this.formatedData = []
        this.getData(0);
      }
    },
    
    getRUDetail(id) {
      this.rudetaildialog = true;
      this.rudetailloading = true;
      // this.commentarydata = [];
      // this.commentarycycledata = {};
      // this.commentarysummarydata = {};
      return this.axios.post("/v2/varianceanalysis/reportingunit/getmyrudetail/", {filter: {reportingunit: id}}).then(dt => {
        if(dt.data.status === "success") {
          let fdt = dt.data.data[0] || {};
          this.rudetail = fdt.reportingunit || {};
        }
        else throw new Error (dt.data.message || "Error fetching data");
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
      }).finally(() => {
        this.rudetailloading = false;
      });
    },
    formatData(data) {
      let ArrayTemp = [];
      this.rucodenamemap = {};
      this.rucodeidmap = {};
      data.forEach((i, k) => {
        this.rucodenamemap[i.code] = i.name;
        this.rucodeidmap[i.code] = i._id;
        ArrayTemp.push({
          __key: k,
          __formatedkey: k,
          _id: i._id,
          indexno: k + 1,
          code: i.code,
          name: i.name,
          allusersset: i.allusersset,
          materialityset_actual: i.materialityset_actual,
          materialityset_forecast: i.materialityset_forecast,
          network: (i.network_detail || {}).name || "NA",
          region: (i.region_detail || {}).name || "NA",
          country: (i.country_detail || {}).name || "NA",
          localcontrol_proforma: i.localcontrol_proforma || false,
          localmanagement_proforma: i.localmanagement_proforma || false,
          regionalcontrol_proforma: i.regionalcontrol_proforma || false,
          regionalmanagement_proforma: i.regionalmanagement_proforma || false,
          localcontrol_forecast: i.localcontrol_forecast || false,
          localmanagement_forecast: i.localmanagement_forecast || false,
          regionalcontrol_forecast: i.regionalcontrol_forecast || false,
          regionalmanagement_forecast: i.regionalmanagement_forecast || false,
          bulkapprove_control: i.bulkapprove_control || false,
          bulkapprove_management : i.bulkapprove_management || false,
          usersflag : i.allusersset===true? "Yes":"No",
          materialityflag_actual: i.materialityset_actual ===true?"Yes":"No",
          materialityflag_forecast: i.materialityset_forecast === true? "Yes":"No",
          approver_list:i?.approver || [], 
          preparer_list:i?.submitter || [], 
          regional_approver_list:i?.regional_approver || [], 
          manager_list:i?.manager || [], 

        });
      });
      // console.log(ArrayTemp)
      // this.formatedData = ArrayTemp;
      return ArrayTemp;
    },
    getDownloadAllAccesslist() {
      this.allaccessdownloaddialog = true;
      this.allaccessdownloadloading = true;
      let url = "/v2/varianceanalysis/reportingunit/downloadallaccessexcellist/";
      this.axios.post(url, {}).then(dt => {
        if(dt.data.status === "success") {
          this.allaccessdownloadlist = dt.data.data;
        }
        else throw new Error (dt.data.message || "Error loading download list");
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
      }).finally(() => {
        this.allaccessdownloadloading = false;
      })
    },
    
    downloadAllAccess(id,filter={}) {
      this.allaccessdownloadloading = true;
      this.warnGenerateFile = false;
      let url = "/v2/varianceanalysis/reportingunit/downloadallaccessexcel/";
      if(id) url = url + id;
      // console.log(this.selectedcommentary);
      this.axios.post(url, {filter: filter}).then(dt => {
        if(dt.data.status === "success") {
          this.getDownloadAllAccesslist();
          let download_data = dt.data.data[0] || {};
          if(id && download_data.upload) this.$nova.downloadFile(undefined,{},"/v2/varianceanalysis/analysis/download/"+download_data._id);
        }
        else throw new Error (dt.data.message || "Error generating download");
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
      }).finally(() => {
        this.allaccessdownloadloading = false;
        this.warnGenerateFile = false;
      })
    },
    getDownloadRUMaterialitylist() {
      this.allrumaterialitydownloaddialog = true;
      this.allrumaterialitydownloadloading = true;
      let url = "/v2/varianceanalysis/reportingunit/downloadallRUMaterialityexcellist";
      this.axios.post(url, {}).then(dt => {
        if(dt.data.status === "success") {
          this.allrumaterialitydownloadlist = dt.data.data;
        }
        else throw new Error (dt.data.message || "Error loading download list");
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
      }).finally(() => {
        this.allrumaterialitydownloadloading = false;
      })
    },

    downloadAllRUMateriality(id,filter={}) {
      this.allrumaterialitydownloadloading = true;
      this.warnGenerateFile = false;
      let url = "/v2/varianceanalysis/reportingunit/downloadallRUMaterialityexcel/";
      if(id) url = url + id;
      filter.year = this.selectedYearToGenerate;
      filter.month =this.selectedMonthToGenerate;
      filter.cycletype = this.selectedCycleTypeToGenerate;
      // console.log(this.selectedcommentary);
      this.axios.post(url, {filter: filter}).then(dt => {
        if(dt.data.status === "success") {
          this.getDownloadRUMaterialitylist();
          let download_data = dt.data.data[0] || {};
          if(id && download_data.upload) this.$nova.downloadFile(undefined,{},"/v2/varianceanalysis/analysis/download/"+download_data._id);
        }
        else throw new Error (dt.data.message || "Error generating download");
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
      }).finally(() => {
        this.allrumaterialitydownloadloading = false;
        this.warnGenerateFile = false;
      })
    }, 

    enableDisableWorkflow(data){
      this.enabledisableworkflowdata = {...data};
      this.enabledisabledialog = true;
    },
    enableDisableWorkflowSubmit() {
      let ndt = {
        localcontrol_proforma: this.enabledisableworkflowdata.localcontrol_proforma,
        localmanagement_proforma: this.enabledisableworkflowdata.localmanagement_proforma,
        regionalcontrol_proforma: this.enabledisableworkflowdata.regionalcontrol_proforma,
        regionalmanagement_proforma: this.enabledisableworkflowdata.regionalmanagement_proforma,
        localcontrol_forecast: this.enabledisableworkflowdata.localcontrol_forecast,
        localmanagement_forecast: this.enabledisableworkflowdata.localmanagement_forecast,
        regionalcontrol_forecast: this.enabledisableworkflowdata.regionalcontrol_forecast,
        regionalmanagement_forecast: this.enabledisableworkflowdata.regionalmanagement_forecast
      }
      this.enabledisableloading = true;
      let url = "/v2/varianceanalysis/reportingunit/enabledisableworkflow/"+this.enabledisableworkflowdata._id;
      // console.log(this.selectedcommentary);
      this.axios.post(url, {data: [ndt]}).then(dt => {
        if(dt.data.status === "success") {
          let udt = dt.data.data[0] || {};
          // this.data[this.enabledisableworkflowdata.__key].localcontrol_proforma = udt.localcontrol_proforma || false;
          // this.data[this.enabledisableworkflowdata.__key].localmanagement_proforma = udt.localmanagement_proforma || false;
          // this.data[this.enabledisableworkflowdata.__key].regionalcontrol_proforma = udt.regionalcontrol_proforma || false;
          // this.data[this.enabledisableworkflowdata.__key].regionalmanagement_proforma = udt.regionalmanagement_proforma || false;
          // this.data[this.enabledisableworkflowdata.__key].localcontrol_forecast = udt.localcontrol_forecast || false;
          // this.data[this.enabledisableworkflowdata.__key].localmanagement_forecast = udt.localmanagement_forecast || false;
          // this.data[this.enabledisableworkflowdata.__key].regionalcontrol_forecast = udt.regionalcontrol_forecast || false;
          // this.data[this.enabledisableworkflowdata.__key].regionalmanagement_forecast = udt.regionalmanagement_forecast || false;
          this.formatedData[this.enabledisableworkflowdata.__key].localcontrol_proforma = udt.localcontrol_proforma || false;
          this.formatedData[this.enabledisableworkflowdata.__key].localmanagement_proforma = udt.localmanagement_proforma || false;
          this.formatedData[this.enabledisableworkflowdata.__key].regionalcontrol_proforma = udt.regionalcontrol_proforma || false;
          this.formatedData[this.enabledisableworkflowdata.__key].regionalmanagement_proforma = udt.regionalmanagement_proforma || false;
          this.formatedData[this.enabledisableworkflowdata.__key].localcontrol_forecast = udt.localcontrol_forecast || false;
          this.formatedData[this.enabledisableworkflowdata.__key].localmanagement_forecast = udt.localmanagement_forecast || false;
          this.formatedData[this.enabledisableworkflowdata.__key].regionalcontrol_forecast = udt.regionalcontrol_forecast || false;
          this.formatedData[this.enabledisableworkflowdata.__key].regionalmanagement_forecast = udt.regionalmanagement_forecast || false;
          this.$store.commit("sbSuccess", "Workflow Updated Successfully");
        }
        else throw new Error (dt.data.message || "Error updating detail");
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
      }).finally(() => {
        this.enabledisableloading = false;
        this.enabledisabledialog = false;
      })
    },
    enableDisableBulkWorkflowSubmit() {
      let ndt = [{
        localcontrol_proforma: this.enabledisableworkflowdata.localcontrol_proforma,
        localmanagement_proforma: this.enabledisableworkflowdata.localmanagement_proforma,
        regionalcontrol_proforma: this.enabledisableworkflowdata.regionalcontrol_proforma,
        regionalmanagement_proforma: this.enabledisableworkflowdata.regionalmanagement_proforma,
        localcontrol_forecast: this.enabledisableworkflowdata.localcontrol_forecast,
        localmanagement_forecast: this.enabledisableworkflowdata.localmanagement_forecast,
        regionalcontrol_forecast: this.enabledisableworkflowdata.regionalcontrol_forecast,
        regionalmanagement_forecast: this.enabledisableworkflowdata.regionalmanagement_forecast
      }]
      this.enabledisableloading = true;
      let url = "/v2/varianceanalysis/reportingunit/bulkenabledisableworkflow/";
      // console.log(this.selectedcommentary);
      this.axios.post(url, { data: { data:ndt, ruids: this.enabledisableworkflowdata.reportingunits || []}}).then(dt => {
        if (dt.data.status === "success") {
          this.getFilterData();
          this.$store.commit("sbSuccess", "Workflow Updated Successfully");
        }
        else throw new Error(dt.data.message || "Error updating detail");
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
      }).finally(() => {
        this.enabledisableloading = false;
        this.bulkenableworkflowdialogue = false;
      })
    },
    enableDisablebulkapprove(item){
      this.enabledisablebulkapprovedata = item;
      this.enabledisablebulkapprovedialog = true
    },
    enableDisablebulkapproveSubmit(){
      let ndt = {
        id: this.enabledisablebulkapprovedata._id  || "",
        bulkapprove_control: this.enabledisablebulkapprovedata.bulkapprove_control || false,
      }
      this.enabledisableloading = true;
      let url = "/v2/varianceanalysis/reportingunit/enabledisablebulkapprove";
      // console.log(this.selectedcommentary);
      this.axios.post(url, { data: [ndt] }).then(dt => {
        if (dt.data.status === "success") {
          if (this.bulkenablebulkapprovedialogue){
            this.getFilterData(this.previousFilter,1);
          }else{
            let udt = dt.data.data[0] || {};
            this.formatedData[this.enabledisablebulkapprovedata.__key].bulkapprove_control = udt.bulkapprove_control || false;
          }
          this.$store.commit("sbSuccess", dt.data.message || "Updated successfully");
        }
        else throw new Error(dt.data.message || "Error updating detail");
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
      }).finally(() => {
        this.enabledisableloading = false;
        this.enabledisablebulkapprovedialog = false;
        this.bulkenablebulkapprovedialogue = false;
      })
    },
    loadNextPage(d) {
      this.page = d?.page || 0
      this.getData(this.page - 1);
    },
    getFilterData(filter = {}, d = this.page) {
      let formatFilter = this.perivousFilter || {}
      formatFilter.allusersset = [-1]
      Object.keys(filter?.filter || {}).forEach(key => {
        if (['limit', 'page'].indexOf(key) === -1) {
          formatFilter[key] = [];
          // let dataKey = this.filterOptions.find((item) => item.key === key);
          (filter?.filter?.[key] || []).forEach((item) => {
            formatFilter[key].push(item._id);
          });
        }
      })
      this.page = filter?.page || d || 0;
      if(typeof formatFilter?.allusersset !== 'undefined' && formatFilter.allusersset.length === 2){
        formatFilter.allusersset = -1
      }
      else if(formatFilter.allusersset[0] === 'yes'){
        formatFilter.allusersset = 1
      }
      else if(formatFilter.allusersset[0] === 'no'){
        formatFilter.allusersset = 0
      }
      else if(formatFilter.allusersset[0] === -1 || formatFilter.allusersset.length === 0){
        formatFilter.allusersset = -1
      }
      formatFilter.page = this.page;
      formatFilter.limit = 50
      this.previousFilter = formatFilter || [];
      this.formatedData = [];
      if(typeof this.previousFilter?.forecastmaterialityset !== 'undefined' && this.previousFilter.forecastmaterialityset.length === 2){
        delete this.previousFilter.forecastmaterialityset
      }
      if(typeof this.previousFilter?.actualmaterialityset !== 'undefined' && this.previousFilter.actualmaterialityset.length === 2){
        delete this.previousFilter.actualmaterialityset
      }
      if(typeof this.previousFilter?.allusersset !== 'undefined' && this.previousFilter.allusersset.length === 2){
        delete this.previousFilter.allusersset
      }
      this.getData(this.page - 1);
      
    },
    generateFile(filter) {
      let formatFilter = {}

      Object.keys(filter?.filter || {}).forEach(key => {
        if(['limit','page'].indexOf(key) === -1){
          formatFilter[key] =[];
          (filter?.filter?.[key] || []).forEach((item) => {
              formatFilter[key].push(item._id);
          });
        }
      })
      if (Object.keys(formatFilter).length === 0) {
        this.warnGenerateFile=true
        console.log('formatFilter is empty');
      } else {
        if(this.allrumaterialitydownloaddialog === true){
          this.downloadAllRUMateriality(null,formatFilter)
        }
        else{
          this.downloadAllAccess(null,formatFilter)
        }
      }
      
    },
    viewFileGeneratedDetails(selectedItem){
      this.selectedFileItem =  selectedItem || {};
      const requiredKeys = ['role', 'region', 'country', 'network', 'code'];

    // Loop through required keys and ensure they exist in the filter object
      requiredKeys.forEach((key) => {
        if (!this.selectedFileItem.filter[key]) {
          // Add the missing key to the filter object with an empty array as value
          this.$set(this.selectedFileItem.filter, key, []);
        }
      }); 
      this.viewfiledetailsdialog=true
      console.log('---this.selectedFileItem---')
      console.log(this.selectedFileItem)

    }
  },
  computed: {
  },
}
</script>